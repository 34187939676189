import localeCs from '@angular/common/locales/cs';
import localeDa from '@angular/common/locales/da';
import localeDeCh from '@angular/common/locales/de-CH';
import localeEn from '@angular/common/locales/en';
import localeEt from '@angular/common/locales/et';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeFrBe from '@angular/common/locales/fr-BE';
import localeFrCh from '@angular/common/locales/fr-CH';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localeLt from '@angular/common/locales/lt';
import localeLv from '@angular/common/locales/lv';
import localeNo from '@angular/common/locales/nb';
import localeNl from '@angular/common/locales/nl';
import localeNlBe from '@angular/common/locales/nl-BE';
import localePl from '@angular/common/locales/pl';
import localeRo from '@angular/common/locales/ro';
import localeSv from '@angular/common/locales/sv';
import arialBold from '@capsizecss/metrics/arial/700';
import arial from '@capsizecss/metrics/arial/regular';
import helveticaNeueLight from '@capsizecss/metrics/helveticaNeue/300';
import helveticaNeueBold from '@capsizecss/metrics/helveticaNeue/700';
import helveticaNeue from '@capsizecss/metrics/helveticaNeue/regular';
import nunitoSansLight from '@capsizecss/metrics/nunitoSans/200';
import nunitoSansSemiBold from '@capsizecss/metrics/nunitoSans/600';
import nunitoSansBold from '@capsizecss/metrics/nunitoSans/700';
import nunitoSans from '@capsizecss/metrics/nunitoSans/regular';
import { BrandConfig } from '../../../storefrontlib/src/layout/main/brand-config';

export const config: BrandConfig = {
  iconPathPrefix: '/assets-papyrus',
  fonts: {
    primary: {
      fallback: [helveticaNeue, arial],
      fontFaceProperties: { fontDisplay: 'optional', fontWeight: 400 },
      src: {
        woff2: '/assets-papyrus/fonts/nunitosans-regular.woff2',
        woff: '/assets-papyrus/fonts/nunitosans-regular.woff',
      },
      metrics: nunitoSans,
    },
    'primary-light': {
      fallback: [<any>helveticaNeueLight, arial],
      fontFaceProperties: { fontDisplay: 'optional', fontWeight: 200 },
      src: {
        woff2: '/assets-papyrus/fonts/nunitosans-light.woff2',
        woff: '/assets-papyrus/fonts/nunitosans-light.woff',
      },
      metrics: nunitoSansLight,
    },
    secondary: {
      fallback: [helveticaNeue, arial],
      fontFaceProperties: { fontDisplay: 'optional', fontWeight: 600 },
      src: {
        woff2: '/assets-papyrus/fonts/nunitosans-semi-bold.woff2',
        woff: '/assets-papyrus/fonts/nunitosans-semi-bold.woff',
      },
      metrics: nunitoSansSemiBold,
    },
    'secondary-bold': {
      fallback: [helveticaNeueBold, arialBold],
      fontFaceProperties: { fontDisplay: 'swap', fontWeight: 700 },
      metrics: nunitoSansBold,
      src: {
        woff2: '/assets-papyrus/fonts/nunitosans-bold.woff2',
        woff: '/assets-papyrus/fonts/nunitosans-bold.woff',
      },
    },
  },
  localeData: [
    [localeCs],
    [localeDa],
    [localeDeCh],
    [localeEn],
    [localeEt],
    [localeFi],
    [localeFrBe],
    [localeFrCh],
    [localeHu],
    [localeIt],
    [localeLt],
    [localeLv],
    [localeNl],
    [localeNlBe],
    [localeNo, 'no'],
    [localePl],
    [localeRo],
    [localeSv],
    [localeFr],
  ],
  stylesheets: ['papyrus.css'],
  links: [
    { rel: 'shortcut icon', type: 'image/x-icon', href: '/assets-papyrus/icons/favicon.ico' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/assets-papyrus/icons/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/assets-papyrus/icons/favicon-16x16.png' },
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/assets-papyrus/icons/apple-touch-icon.png' },
    { rel: 'apple-touch-icon-precomposed', href: '/assets-papyrus/icons/apple-touch-icon-precomposed.png' },
    { rel: 'mask-icon', href: '/assets-papyrus/icons/safari-pinned-tab.svg', color: '#28539c' },
    { rel: 'manifest', href: '/assets-papyrus/manifest.webmanifest' },
    { rel: 'preload', href: '/assets-papyrus/fonts/nunitosans-light.woff2', as: 'font', type: 'font/woff2', crossorigin: '' },
    { rel: 'preload', href: '/assets-papyrus/fonts/nunitosans-bold.woff2', as: 'font', type: 'font/woff2', crossorigin: '' },
    { rel: 'preload', href: '/assets-papyrus/fonts/nunitosans-regular.woff2', as: 'font', type: 'font/woff2', crossorigin: '' },
    { rel: 'preload', href: '/assets-papyrus/fonts/nunitosans-semi-bold.woff2', as: 'font', type: 'font/woff2', crossorigin: '' },
  ],
  meta: [
    { name: 'application-name', content: 'Papyrus' },
    { name: 'msapplication-config', content: '/assets-papyrus/browserconfig.xml' },
    { name: 'theme-color', content: '#ffffff' },
  ],
};
