import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { RoutingService } from '@spartacus/core';
import { BehaviorSubject, Observable, Subscription, combineLatest, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ArticleService, CategoryService, ProductService } from '../../../core/catalog';
import {
  Article,
  Category,
  DiscontinuedArticleViewType,
  EntityMap,
  Product,
  SearchTermFacetMappingHint,
  SolrResultEntityRef,
  SolrSearchResult,
  SolrSearchType,
  SubstituteRef,
  SubstituteRefType,
  SubstituteType,
} from '../../../core/model';
import { SearchService } from '../../../core/search';
import { PrincipalConfigurationService } from '../../../core/user';
import { REGEX_LETTERS, shallowEqualObjects } from '../../../core/util';
import { WindowRef } from '../../../core/window';
import { CatalogTabTypes } from '../../../features/catalog/container/catalog/catalog-search.service';
import { DrawerPlacement } from '../../services/drawer/drawer-options';
import { DrawerService } from '../../services/drawer/drawer.service';

// Search result limits
const MAX_ARTICLES_RESULTS = 4;
const MAX_CATEGORY_RESULTS = 5;
const MAX_PRODUCT_RESULTS = 8;
const NUMBER_OF_DIGITS_REQUIRED_FOR_ARTICLE_SEARCH = 4;
const MIN_SEARCH_LENGTH = 2;

@Component({
  selector: 'py-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly minSearchLength = MIN_SEARCH_LENGTH;

  resettable: boolean;

  @ViewChild('searchHolder')
  searchHolder: ElementRef<HTMLInputElement>;
  @ViewChild('searchInput')
  searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('drawerTemplate') drawerTemplate: TemplateRef<any>;

  @Input()
  set hidden(hidden: boolean) {
    this.hidden$.next(hidden);
  }

  get hidden(): boolean {
    return this.hidden$.getValue();
  }

  hidden$ = new BehaviorSubject<boolean>(false);

  isSearchContainerOpen: boolean = false;
  form: UntypedFormGroup;
  lastSearchedText: string = '';
  showSearchResults$ = new BehaviorSubject<boolean>(false);
  searchResults$: Observable<SolrSearchResult>;
  articleResultRefs$: Observable<SolrResultEntityRef[]>;
  products$: Observable<Observable<Product>[]>;
  categories$: Observable<Category[]>;

  catalogItemQueryParam$: Observable<EntityMap<string>>;
  allResultsQueryParam$: Observable<EntityMap<string>>;
  enableTogglingOfCustomerAssortment$: Observable<boolean>;
  defaultCustomerAssortmentToggleState$: Observable<boolean>;
  searchResultsTabType$: Observable<CatalogTabTypes>;
  searchBoxDisplayArticlesThreshold$: Observable<number>;
  searchBoxDisplayArticlesThreshold: number;
  enableAdditionalSearchBoxDisplayArticlesLogic$: Observable<boolean>;
  searchResultsSecondaryVariant$: Observable<boolean>;

  hasDiscontinuedArticle: boolean = false;
  discontinuedArticle: Article;
  substituteRefs: SubstituteRef[] = [];
  substituteRefsLoading$: Observable<boolean>;
  substituteRefsLoaded$: Observable<boolean>;
  discontinuedArticleViewType: DiscontinuedArticleViewType;

  userInputSearchInProgress$: Observable<boolean>;
  searchLoading$: Observable<boolean>;
  searchLoaded$: Observable<boolean>;

  maxSearchResultsContainerHeight$: Observable<number>;

  searchHints$: Observable<SearchTermFacetMappingHint[]>;
  activeTab$ = new BehaviorSubject<CatalogTabTypes | undefined>(undefined);

  shouldLoadProducts: boolean;

  private subscription = new Subscription();
  private searchHolderPreviousPosition: number;
  private viewInit$ = new BehaviorSubject<boolean>(false);

  constructor(
    private principalConfigurationService: PrincipalConfigurationService,
    private searchService: SearchService,
    private articleService: ArticleService,
    private productService: ProductService,
    private categoryService: CategoryService,
    protected router: Router,
    private routingService: RoutingService,
    protected activatedRoute: ActivatedRoute,
    protected cd: ChangeDetectorRef,
    private drawerService: DrawerService,
    private windowRef: WindowRef
  ) {}

  get showOnlyStarredControl(): UntypedFormControl {
    return this.form.get('showOnlyStarred') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      search: new UntypedFormControl(),
      showOnlyStarred: new UntypedFormControl(false),
    });

    this.subscription.add(
      this.principalConfigurationService
        .isEnabled('defaultCustomerAssortmentToggleState')
        .pipe(distinctUntilChanged())
        .subscribe((defaultCustomerAssortmentToggleState) => {
          this.showOnlyStarredControl.setValue(defaultCustomerAssortmentToggleState);
        })
    );

    this.searchHints$ = this.searchService.getHints();

    this.showSearchResults$ = this.searchService.getShowSearchResults$();

    this.subscription.add(
      combineLatest([this.showSearchResults$, this.hidden$, this.viewInit$])
        .pipe(
          filter(([_toggle, _hidden, viewInit]) => viewInit),
          map(([toggle, hidden]) => toggle && !hidden),
          debounceTime(20),
          distinctUntilChanged()
        )
        .subscribe((toggle) => {
          this.toggleDrawer(toggle);
        })
    );

    this.userInputSearchInProgress$ = this.searchService.getUserInputSearchInProgress$().asObservable();

    const searchQuery$: Observable<string> = combineLatest([
      this.hidden$,
      this.searchService.getTextFromSearchInput$(),
      this.showOnlyStarredControl.valueChanges.pipe(startWith(this.showOnlyStarredFormValue || false), distinctUntilChanged()),
    ]).pipe(
      map(([hidden, text]) => (hidden ? '' : text)),
      distinctUntilChanged(),
      map((value) => this.getSearchQuery(value)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.searchLoading$ = searchQuery$.pipe(
      switchMap((searchQuery) => this.searchService.getSearchLoading(SolrSearchType.DEFAULT, searchQuery))
    );
    this.searchLoaded$ = searchQuery$.pipe(
      switchMap((searchQuery) => this.searchService.getSearchLoaded(SolrSearchType.DEFAULT, searchQuery))
    );
    this.searchBoxDisplayArticlesThreshold$ = this.principalConfigurationService
      .getValue('searchBoxDisplayArticlesThreshold')
      .pipe(
        map((config) => config?.value as number),
        map((value) => (value === undefined ? 0 : value)),
        tap((value) => (this.searchBoxDisplayArticlesThreshold = value))
      );

    this.enableAdditionalSearchBoxDisplayArticlesLogic$ = this.principalConfigurationService
      .isEnabled('enableAdditionalSearchBoxDisplayArticlesLogic')
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.searchResultsSecondaryVariant$ = this.principalConfigurationService
      .isEnabled('searchResultsSecondary')
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.enableTogglingOfCustomerAssortment$ = this.principalConfigurationService
      .isEnabled('enableTogglingOfCustomerAssortment')
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.catalogItemQueryParam$ = combineLatest([
      this.enableTogglingOfCustomerAssortment$,
      this.showOnlyStarredControl.valueChanges.pipe(startWith(this.showOnlyStarredFormValue)),
    ]).pipe(
      map(([enableTogglingOfCustomerAssortment, showOnlyStarred]) =>
        enableTogglingOfCustomerAssortment ? { mya: showOnlyStarred ? '1' : '0' } : {}
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.searchResults$ = searchQuery$.pipe(
      switchMap((searchQuery) =>
        this.searchService
          .getResults(SolrSearchType.DEFAULT, searchQuery)
          .pipe(distinctUntilChanged(shallowEqualObjects), shareReplay({ bufferSize: 1, refCount: true }))
      )
    );

    this.articleResultRefs$ = this.searchResults$.pipe(
      withLatestFrom(
        this.searchBoxDisplayArticlesThreshold$,
        this.enableAdditionalSearchBoxDisplayArticlesLogic$,
        this.searchResultsSecondaryVariant$
      ),
      map(
        ([
          searchResults,
          searchBoxDisplayArticlesThreshold,
          enableAdditionalSearchBoxDisplayArticlesLogic,
          searchResultsSecondaryVariant,
        ]) => {
          if (
            !!this.searchFormValue &&
            !!searchResults?.articleResultRefs?.length &&
            (this.articleSearch(this.searchFormValue, enableAdditionalSearchBoxDisplayArticlesLogic) ||
              !searchResults?.productRefs?.length ||
              searchResultsSecondaryVariant ||
              searchResults?.articleResultRefs?.length <= searchBoxDisplayArticlesThreshold) // is article search, or no products found, or articles not exceeded threshold
          ) {
            return searchResults?.articleResultRefs?.slice(0, MAX_ARTICLES_RESULTS) || [];
          }
          return [];
        }
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.subscription.add(
      this.articleResultRefs$
        .pipe(
          tap((_) => this.resetDiscontinuedArticleData()),
          filter((articleResultRefs) => !!articleResultRefs?.length),
          tap((articleResultRefs) => {
            this.articleService.getArticles(articleResultRefs?.map((articleResultRef) => articleResultRef.ref) || []);
          }),
          filter((articleResultRefs) => articleResultRefs?.length === 1),
          map(
            (articleResultRefs: SolrResultEntityRef[]) =>
              articleResultRefs?.find((articleResultRef) => articleResultRef.ref === this.searchFormValue?.trim())?.ref
          ),
          switchMap((articleRef: string) => this.articleService.getArticle(articleRef)),
          filter((article: Article) => !!article && this.articleService.isArticleDiscontinued(article)),
          tap((discontinuedArticle) => {
            this.hasDiscontinuedArticle = true;
            this.discontinuedArticle = discontinuedArticle;
          }),
          switchMap((discontinuedArticle) => {
            this.substituteRefsLoading$ = this.articleService.getSubstitutesArticlesLoading(discontinuedArticle.code);
            this.substituteRefsLoaded$ = this.articleService.getSubstitutesArticlesLoaded(discontinuedArticle.code);
            return this.articleService.getSubstitutesArticles(discontinuedArticle.code);
          })
        )
        .subscribe((substitutes: SubstituteRef[]) => {
          this.substituteRefs = substitutes ? substitutes : [];
          this.setDiscontinuedArticleViewType(this.substituteRefs);
        })
    );

    this.products$ = combineLatest([
      this.searchResults$,
      this.enableTogglingOfCustomerAssortment$,
      this.searchBoxDisplayArticlesThreshold$,
      this.enableAdditionalSearchBoxDisplayArticlesLogic$,
      this.searchResultsSecondaryVariant$,
    ]).pipe(
      switchMap(
        ([
          searchResults,
          enableTogglingOfCustomerAssortment,
          searchBoxDisplayArticlesThreshold,
          enableAdditionalSearchBoxDisplayArticlesLogic,
          searchResultsSecondaryVariant,
        ]) => {
          const noArticlesOrArticlesThresholdExceeded =
            searchResults?.articleResultRefs?.length === 0 ||
            searchResults?.articleResultRefs?.length > searchBoxDisplayArticlesThreshold;

          if (searchResults?.articleResultRefs?.length === 0 && searchResults?.productRefs?.length > 0) {
            this.shouldLoadProducts = true;
          } else if (searchResults?.articleResultRefs?.length > 0 && searchResults?.productRefs?.length === 0) {
            this.shouldLoadProducts = false;
          } else {
            this.shouldLoadProducts =
              !!this.searchFormValue &&
              !!searchResults?.productRefs?.length &&
              !this.articleSearch(this.searchFormValue, enableAdditionalSearchBoxDisplayArticlesLogic) &&
              noArticlesOrArticlesThresholdExceeded;
          }

          // Products need to be loaded for secondary search results, because both tabs (products and articles) should be visible in search results
          return searchResultsSecondaryVariant || this.shouldLoadProducts
            ? this.getProducts(searchResults, enableTogglingOfCustomerAssortment)
            : of([]);
        }
      )
    );

    this.searchResultsTabType$ = combineLatest([
      this.articleResultRefs$,
      this.products$,
      this.searchResultsSecondaryVariant$,
    ]).pipe(
      map(([articleResultRefs, products, searchResultsSecondaryVariant]) => {
        let activeTab;
        if (searchResultsSecondaryVariant) {
          activeTab = this.shouldLoadProducts ? CatalogTabTypes.Products : CatalogTabTypes.Articles;
        } else {
          activeTab = articleResultRefs?.length && !products?.length ? CatalogTabTypes.Articles : CatalogTabTypes.Products;
        }

        this.activeTab$.next(activeTab);
        return activeTab;
      }),
      startWith(CatalogTabTypes.Products)
    );

    this.categories$ = combineLatest([
      this.searchResults$,
      this.enableTogglingOfCustomerAssortment$,
      this.enableAdditionalSearchBoxDisplayArticlesLogic$,
    ]).pipe(
      switchMap(([searchResults, enableTogglingOfCustomerAssortment, enableAdditionalSearchBoxDisplayArticlesLogic]) => {
        if (
          !!this.searchFormValue &&
          !!searchResults?.categoryRefs?.length &&
          !this.articleSearch(this.searchFormValue, enableAdditionalSearchBoxDisplayArticlesLogic)
        ) {
          return this.categoryService.getCategoriesByRefs(
            searchResults?.categoryRefs?.slice(0, MAX_CATEGORY_RESULTS) || [],
            enableTogglingOfCustomerAssortment ? `mya:${this.showOnlyStarredFormValue}` : 'default'
          );
        }
        return of([]);
      })
    );

    this.subscription.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (this.showSearchResults$.getValue()) {
            this.updateShowSearchResults(false);
          }
        }
      })
    );

    this.subscription.add(
      this.form
        .get('search')
        .valueChanges.pipe(
          tap(() => this.searchService.updateUserInputSearchInProgress$(true)),
          debounceTime(400),
          tap((searchValue) => {
            this.searchService.updateTextFromSearchInput$(searchValue);
          }),
          filter((searchValue) => !!searchValue && searchValue?.length >= MIN_SEARCH_LENGTH)
        )
        .subscribe((searchValue) => {
          this.doSearch(searchValue);
        })
    );

    this.subscription.add(
      this.showOnlyStarredControl.valueChanges.subscribe((_toggleState) => {
        if (this.showSearchResults$.getValue()) {
          this.doSearch(this.searchFormValue);
        }
      })
    );

    this.subscription.add(
      this.searchService.getTextFromSearchInput$().subscribe((text) => {
        const searchField = this.form.get('search');
        searchField.reset(text, { emitEvent: false });
        this.resettable = text?.length > 0 ? true : false;
        this.cd.detectChanges();
      })
    );

    this.maxSearchResultsContainerHeight$ = this.windowRef.resize$.pipe(
      map((event) => {
        const windowHeight = event?.target?.innerHeight;
        const searchHolderElement = this.searchHolder?.nativeElement;
        if (windowHeight && searchHolderElement) {
          this.searchHolderPreviousPosition = searchHolderElement.getBoundingClientRect().bottom;
          return windowHeight - this.searchHolderPreviousPosition;
        }
        return undefined;
      })
    );
  }

  ngAfterViewInit(): void {
    this.viewInit$.next(true);
  }

  private triggerSearchResultsContainerResizeIfNeeded(): void {
    // If position of header element was changed (e.g. operational alert appeared), then search results container needs to be resize.
    if (
      this.windowRef.isBrowser() &&
      this.searchHolder?.nativeElement.getBoundingClientRect().bottom !== this.searchHolderPreviousPosition
    ) {
      const window = this.windowRef.nativeWindow;
      window.dispatchEvent(new Event('resize'));
    }
  }

  get showOnlyStarredFormValue(): boolean {
    return this.showOnlyStarredControl.value;
  }

  get searchFormValue(): string {
    return this.searchService.getTextFromSearchInput$()?.value;
  }

  private doSearch(searchValue: string): void {
    if (!searchValue || searchValue?.length < MIN_SEARCH_LENGTH) {
      return;
    }
    if (searchValue?.length >= MIN_SEARCH_LENGTH && !this.showSearchResults$.getValue()) {
      this.updateShowSearchResults(true);
    }

    this.lastSearchedText = searchValue;
    this.searchService.search(this.getSearchQuery(searchValue), {
      pageSize: Math.max(MAX_PRODUCT_RESULTS, MAX_ARTICLES_RESULTS, this.searchBoxDisplayArticlesThreshold + 1), // We must load amount of items that allows showing specified no. of articles and products and also checking if threshold was exceeded
      currentPage: 0,
      searchType: SolrSearchType.DEFAULT,
      mya: this.showOnlyStarredFormValue || false,
    });
  }

  /**
   * Splits search term by spaces into array of substrings and returns true if the array has both:
   * - substring that is a word made of letters
   * - substring that starts with a number
   * e.g. "coated 300g", "reamed 450x640", "30mm envelope" etc.
   */
  private hasWordAndNumber(value: string): boolean {
    const substrings = value.trim().split(/\s+/);

    return (
      substrings.some((substring) => REGEX_LETTERS.test(substring)) && substrings.some((substring) => /^[0-9]+/.test(substring))
    );
  }

  private getSearchQuery(value: string, addMya: boolean = true): string {
    const query = `${value.replace(':', ' ')}::`;
    if (addMya) {
      return `${query}mya:${this.showOnlyStarredFormValue ? '1' : '0'}`;
    }
    return query;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onEnterPressed(): void {
    this.subscription.add(
      this.searchService
        .getUserInputSearchInProgress$()
        .pipe(
          filter((inProgress) => !inProgress),
          take(1)
        )
        .subscribe(() => (this.hasDiscontinuedArticle ? this.onDiscontinuedArticleSubmit() : this.onSubmit()))
    );
  }

  onEscapePressed(): void {
    this.searchInput?.nativeElement.blur();
  }

  onSubmit(): void {
    const activeTab = this.activeTab$?.getValue();

    this.subscription.add(
      this.searchResults$
        .pipe(take(1), withLatestFrom(this.searchResultsTabType$, this.searchResultsSecondaryVariant$))
        .subscribe(([result, tabType, searchResultsSecondaryVariant]) => {
          const search = this.searchFormValue;
          if (result && (result.articleResultRefs?.length || result.productRefs?.length)) {
            // For secondary search results view search results tabs can be switched, so correct tab needs to be set as navigation parameter
            const selectedTab = searchResultsSecondaryVariant ? activeTab : tabType;

            this.updateShowSearchResults(false);

            this.routingService.go(
              { cxRoute: 'catalog' },
              {
                queryParams: {
                  query: this.getSearchQuery(search, false),
                  mya: this.showOnlyStarredFormValue ? 1 : 0,
                  tab: selectedTab,
                },
              }
            );
          } else {
            this.doSearch(search);
          }
        })
    );
  }

  onDiscontinuedArticleSubmit(): void {
    const tabType = this.substituteRefs.some(
      (substitute) =>
        substitute.substituteType === SubstituteType.Alternative || substitute.substituteType === SubstituteType.Replacement
    )
      ? CatalogTabTypes.Articles
      : CatalogTabTypes.Products;

    this.updateShowSearchResults(false);
    this.routingService.go(
      { cxRoute: 'catalog' },
      {
        queryParams: {
          query: this.getSearchQuery(this.searchFormValue, false),
          mya: this.showOnlyStarredFormValue ? 1 : 0,
          tab: tabType,
          discontinued: true,
        },
      }
    );
  }

  focus(): void {
    this.triggerSearchResultsContainerResizeIfNeeded();

    this.subscription.add(
      this.searchResults$
        .pipe(
          take(1),
          filter(() => this.isSearchContainerOpen === false)
        )
        .subscribe((result) => {
          if (Object.keys(result).length === 0 || this.lastSearchedText !== this.searchFormValue) {
            this.doSearch(this.searchFormValue);
          }

          this.isSearchContainerOpen = true;
          this.updateShowSearchResults(true);
        })
    );
  }

  articleSearch(value: string, enableAdditionalSearchBoxDisplayArticlesLogic: boolean): boolean {
    return (
      (/^\d+$/.test(value) && value.length >= NUMBER_OF_DIGITS_REQUIRED_FOR_ARTICLE_SEARCH) ||
      (enableAdditionalSearchBoxDisplayArticlesLogic ? this.hasWordAndNumber(value) : false)
    );
  }

  updateShowSearchResults(showSearchResults: boolean): void {
    this.searchService.updateShowSearchResults$(showSearchResults);
  }

  setDiscontinuedArticleViewType(substitutes: SubstituteRef[]): void {
    this.discontinuedArticleViewType = substitutes.some(
      (substitute) =>
        substitute.refType === SubstituteRefType.Article &&
        (substitute.substituteType === SubstituteType.Alternative || substitute.substituteType === SubstituteType.Replacement)
    )
      ? DiscontinuedArticleViewType.Article
      : DiscontinuedArticleViewType.Product;
  }

  onResetSearchInput(): void {
    this.form.patchValue({ search: '' }, { emitEvent: false });
    this.updateShowSearchResults(false);
    this.searchService.updateTextFromSearchInput$('');
    this.drawerService.closeOpenDrawer();
    this.lastSearchedText = '';
    this.cd.detectChanges();
  }

  toggleDrawer(open: boolean): void {
    this.searchInput?.nativeElement.blur();

    if (open && !this.drawerService?.isDrawerOpen(this.drawerTemplate)) {
      this.subscription.add(
        this.drawerService
          .open({
            template: this.drawerTemplate,
            placement: DrawerPlacement.Top,
          })
          .pipe(
            take(1),
            mergeMap((drawer) => {
              const observables = [];

              observables.push(
                drawer?.afterOpen?.pipe(
                  take(1),
                  tap(() => {
                    this.searchInput?.nativeElement.focus();
                    this.cd.detectChanges();
                  })
                )
              );

              observables.push(
                drawer?.afterClose?.pipe(
                  take(1),
                  tap(() => {
                    this.isSearchContainerOpen = false;
                    if (!this.hidden) {
                      this.updateShowSearchResults(false);
                    }
                    this.cd.detectChanges();
                  })
                )
              );

              return combineLatest(observables);
            })
          )
          .subscribe()
      );
    }
  }

  private getProducts(
    searchResults: SolrSearchResult,
    enableTogglingOfCustomerAssortment: boolean
  ): Observable<Observable<Product>[]> {
    return this.productService.getProducts(
      searchResults?.productRefs?.slice(0, MAX_PRODUCT_RESULTS) || [],
      enableTogglingOfCustomerAssortment ? `mya:${this.showOnlyStarredFormValue}` : 'default'
    );
  }

  private resetDiscontinuedArticleData(): void {
    this.hasDiscontinuedArticle = false;
    this.discontinuedArticle = undefined;
    this.substituteRefs = [];
  }
}
