<py-badge
  *ngIf="!!productResultRef && (hasSearchScore$ | async)"
  (click)="openSearchScoreModal()"
  [type]="productResultRef.topScore ? 'success' : 'info'"
  class="searchScoreBadge"
>
  <i nz-icon nzType="trophy" nzTheme="fill" *ngIf="productResultRef.topScore"></i>
  {{ productResultRef.score }}
</py-badge>
<cx-generic-link
  *ngIf="product"
  [url]="getUrl()"
  class="catalog-product-list-item-link"
  [class.catalog-product-list-item-link-secondary]="isSecondaryVersion"
>
  <div class="catalog-product-list-item" [class.catalog-product-list-item-secondary]="isSecondaryVersion">
    <div class="d-flex flex-column h-100 catalog-product-list-item-content-wrapper">
      <div class="d-flex pb-4 flex-column catalog-product-list-item-content">
        <div class="badges">
          <py-badge *ngIf="product?.overlayNew" type="new" [tag]="true" large="true">{{
            'catalog.new_badge' | cxTranslate
          }}</py-badge>
          <ng-container *pyPrincipalConfiguration="'enableDisplayOutgoingArea'">
            <py-badge
              *ngIf="
                product?.outgoing?.hasOutgoingOnSaleArticles ||
                (product?.outgoing?.hasOutgoingArticles &&
                  ((enableProductOutletBadgeOnlyIfAllArticlesAreOutlet$ | async)
                    ? !product?.outgoing?.hasNonOutgoingArticles
                    : true))
              "
              type="outlet"
              [tag]="true"
              large="true"
            >
              {{ 'catalog.outlet_badge' | cxTranslate }}
            </py-badge>
          </ng-container>

          <ng-container *pyPrincipalConfiguration="'displaybadgeforZTarticle'">
            <py-badge *ngIf="product?.outgoing?.hasLastChanceArticles" type="last-chance" [tag]="true" large="true">
              {{ 'catalog.lastChance_badge' | cxTranslate }}
            </py-badge>
          </ng-container>

          <py-badge *ngIf="substituteBadgeType === substituteTypes?.Alternative" type="alternative" [tag]="true" large="true">{{
            'catalog.substitute_alternative_badge' | cxTranslate
          }}</py-badge>
          <py-badge *ngIf="substituteBadgeType === substituteTypes?.Replacement" type="replacement" [tag]="true" large="true">{{
            'catalog.substitute_replacement_badge' | cxTranslate
          }}</py-badge>
          <py-badge *ngIf="substituteBadgeType === substituteTypes?.Recommendation" type="recommended" [tag]="true" large="true">
            {{ 'catalog.substitute_recommendation_badge' | cxTranslate }}</py-badge
          >

          <py-badge *ngIf="product?.priceCampaign" type="campaign" [tag]="true" large="true"
            >{{ 'common.campaign_badge' | cxTranslate }}
          </py-badge>
        </div>

        <div
          *ngIf="product?.images && product?.images?.length; else noImageTemplate"
          class="catalog-product-list-item-image"
          [class.catalog-product-list-item-image-secondary]="isSecondaryVersion"
        >
          <img [pyMedia]="product.images[0]" [alt]="product?.name" />
        </div>
        <span class="catalog-product-list-item-product-name mt-2">{{ product?.name }}</span>
        <div
          *ngIf="isSecondaryVersion"
          class="catalog-product-list-item-description mt-2 px-4"
          [innerHTML]="product?.description"
        ></div>

        <div class="colors mt-2 mb-2" *ngIf="product?.colors">
          <span *ngFor="let color of product?.colors?.slice(0, 5)" class="color" [style.background-color]="color"></span>
          <span *ngIf="product?.colors?.length > 5" class="more-colors">+{{ product?.colors?.length - 5 }}</span>
        </div>
      </div>
    </div>
  </div>
</cx-generic-link>

<ng-template #noImageTemplate>
  <div class="d-flex justify-content-center mb-2">
    <i nz-icon nzType="picture" class="no-image-icon"></i>
  </div>
</ng-template>
